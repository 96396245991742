import request from "@/config/serve.js";

// 查询
export function getLaunchPolicyList(data) {
  return request("post", "/apiGo/api/v1/app/manage/getLaunchPolicyList", data);
}
// 查询详情
export function getLaunchPolicyInfo(params) {
  return request(
    "post",
    "/apiGo/api/v1/app/manage/getLaunchPolicyInfo",
    params
  );
}
//新增&修改
export function saveLaunchPolicy(data) {
  return request("post", "/apiGo/api/v1/app/manage/saveLaunchPolicy", data);
}
//删除
export function delLaunchPolicy(data) {
  return request("post", "/apiGo/api/v1/app/manage/delLaunchPolicy", data);
}
